
import { Component, Vue } from 'vue-property-decorator'
import { authService } from '@/services/auth.service'
import { userService, USER_RIGHTS } from '@/services/user.service'
import { dashboardService } from '@/services/dashboard.service'
import DashboardChart from '@/components/dashboard/DashboardChart.vue'
import DashboardChartCa from '@/components/dashboard/DashboardChartCa.vue'
import DashboardBarChart from '@/components/dashboard/DashboardBarChart.vue'
import DashboardLineChart from '@/components/dashboard/DashboardLineChart.vue'
import DashboardDocuments from '@/components/dashboard/DashboardDocuments.vue'
import DashboardFolders from '@/components/dashboard/DashboardFolders.vue'
import DashboardNote from '@/components/dashboard/DashboardNote.vue'
import DashboardReferences from '@/components/dashboard/DashboardReferences.vue'
import DashboardNotification from '@/components/dashboard/DashboardNotification.vue'
import CustomToggleDouble from '@/components/form/ToggleDouble.vue'
import DashboardMetricTemplate from '@/components/dashboard/DashboardMetricTemplate.vue'
import DashboardRefAndGedTable from '@/components/dashboard/DashboardRefAndGedTable.vue'
    @Component({
      subscriptions () {
        return {
          authUser$: authService.onChange,
          summaryData$: dashboardService.onChangeSummaryData
        }
      },
      components: {
        DashboardMetricTemplate,
        DashboardChart,
        DashboardBarChart,
        DashboardLineChart,
        DashboardDocuments,
        DashboardFolders,
        DashboardNote,
        DashboardReferences,
        DashboardNotification,
        CustomToggleDouble,
        DashboardChartCa,
        DashboardRefAndGedTable
      }
    })
export default class Dashboard extends Vue {
  authUser$: any = {}
  summaryData$: any = {}
  typeList : string = 'documents'
  validationFilters : any = {
    settleFolderNotStatus: 7,
    legalFolderNotStatus: 11,
    type: 'folder'
  }

  openedFoldersFilters : any = {
    status: 'Ouvert',
    type: 'folder'
  }

  get hasAdminRight (): boolean {
    return userService.hasRight(this.authUser$, USER_RIGHTS.DASHBOARD_ADMIN)
  }

  get hasGeneralDashboardRight (): boolean {
    return userService.hasRight(this.authUser$, USER_RIGHTS.DASHBOARD_GEN)
  }

  get hasDocumentalistDashboardRight (): boolean {
    return userService.hasRight(this.authUser$, USER_RIGHTS.DASHBOARD_DOCUMENTALISTE)
  }

  created () {

  }

  /**
       * scrollTo - Horizontal Scrolling
       * @param {(HTMLElement ref)} element - Scroll Container
       * @param {number} scrollPixels - pixel to scroll
       * @param {number} duration -  Duration of scrolling animation in millisec
       */
  scrollTo (element: any, scrollPixels: any, duration: any) {
    const scrollPos = element.scrollLeft
    // Condition to check if scrolling is required
    if (!((scrollPos === 0 || scrollPixels > 0) && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) {
      // Get the start timestamp
      const startTime =
              'now' in window.performance
                ? performance.now()
                : new Date().getTime()

      function scroll (timestamp : any) {
        // Calculate the timeelapsed
        const timeElapsed = timestamp - startTime
        // Calculate progress
        const progress = Math.min(timeElapsed / duration, 1)
        // Set the scrolleft
        element.scrollLeft = scrollPos + scrollPixels * progress
        // Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
        if (timeElapsed < duration) {
          // Request for animation
          window.requestAnimationFrame(scroll)
        }
      }
      // Call requestAnimationFrame on scroll function first time
      window.requestAnimationFrame(scroll)
    }
  }

  swipeLeft () {
    const content = this.$refs.content
    this.scrollTo(content, -300, 800)
  }

  swipeRight () {
    const content = this.$refs.content
    this.scrollTo(content, 300, 800)
  }
}
