
import { Component, Vue, Watch } from 'vue-property-decorator'
import { legalFolderService } from '@/services/legalFolder.service'
import { LegalFolder } from '@/models/legalFolder.model'
import { billingService } from '@/services/billing.service'

@Component({
  subscriptions () {
    return {
      billing$: billingService.onChangeBilling,
      folder$: legalFolderService.onChangeLegalFolder
    }
  }
})
export default class LegalFolderStatusModal extends Vue {
  status: number = 12
  active: boolean = false
  reason: string | null = null
  invalidReason: boolean = false
  folder$: LegalFolder = new LegalFolder()

  @Watch('status')
  onChangeStatus () {
    this.invalidReason = false
  }

  open (): void {
    this.active = true
    this.reset()
  }

  close (): void {
    this.active = false
  }

  reset (): void {
    this.status = 12
    this.reason = null
    this.invalidReason = false
  }

  handleConfirm (): void {
    if (this.status !== 12 && !this.reason) {
      this.invalidReason = true
      return
    } else if (this.status === 12) {
      this.reason = null
    }
    this.$emit('validate', { status: this.status, reason: this.reason })
    this.close()
  }
}
