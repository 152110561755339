
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SettledFolder, settledFolderService } from '@/services/settledFolder.service'
import { billingService } from '@/services/billing.service'

@Component({
  subscriptions () {
    return {
      billing$: billingService.onChangeBilling,
      folder$: settledFolderService.onChangeFolder
    }
  }
})
export default class SettledFolderStatusModal extends Vue {
  status: number = 9
  active: boolean = false
  reason: string | null = null
  invalidReason: boolean = false
  folder$: SettledFolder = new SettledFolder()

  @Watch('status')
  onChangeStatus () {
    this.invalidReason = false
  }

  open (): void {
    this.active = true
    this.reset()
  }

  close (): void {
    this.active = false
  }

  reset (): void {
    this.status = 9
    this.reason = null
    this.invalidReason = false
  }

  handleConfirm (): void {
    if (this.status !== 9 && !this.reason) {
      this.invalidReason = true
      return
    } else if (this.status === 9) {
      this.reason = null
    }
    this.$emit('validate', { status: this.status, reason: this.reason })
    this.close()
  }
}
