import axios from 'axios'
import { apiClient } from './api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { MISSION_DATE_VALUES, REQUESTED_FILES } from './constants.service'
import { billingService } from './billing.service'
import { authService } from './auth.service'
import { fileService, AppFile, CategoryTree } from './file.service'
import { Service } from '@/services/Service'
import { VUE_APP_RAPPORT_API_URL } from '@/constants'
import { removeSpecialCharacters } from '@/utils/functions'

export class QuoteAddress {
  id: string
  quoteId: string
  buildingNumber: string
  street: string
  zip: string
  city: string
  lat: string
  lon: string
  address: string
  categories: CategoryTree[]

  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.quoteId = (data && data.quoteId) || null
    this.buildingNumber = (data && data.buildingNumber) || ''
    this.street = (data && data.street) || ''
    this.zip = (data && data.zip) || ''
    this.city = (data && data.city) || ''
    this.lat = (data && data.lat) || ''
    this.lon = (data && data.lon) || ''
    this.address = (data && data.address) || ''
    this.categories = (data && data.categories) || []
  }
}

export class QuoteMission {
  id: string
  quoteId: string
  number: string
  expertType: string
  localType: string
  option: boolean
  amount: number | null

  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.quoteId = (data && data.quoteId) || null
    this.number = (data && data.number) || ''
    this.expertType = (data && data.expertType) || ''
    this.localType = (data && data.localType) || ''
    this.option = (data && data.option) || false
    this.amount = (data && Math.floor(data.amount)) || 0
  }
}

export class QuoteContact {
  id: string
  quoteId: number
  contactId: number
  type: string
  role: string
  contactAddressId: string
  telephone: string
  email: number | null
  interlocuteur: boolean
  mandator: boolean
  contactType: boolean
  contact: any
  signer: boolean
  follower: boolean
  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.quoteId = (data && data.quoteId) || null
    this.contactId = (data && data.contactId) || null
    this.type = (data && data.type) || ''
    this.role = (data && data.role) || ''
    this.contactAddressId = (data && data.contactAddressId) || null
    this.telephone = (data && data.telephone1) || ''
    this.email = (data && data.email) || null
    this.interlocuteur = (data && data.interlocuteur) || false
    this.mandator = (data && data.mondator) || false
    this.contactType = (data && data.contactType) || false
    this.contact = (data && data.contact) || null
    this.signer = (data && data.signer) || false
    this.follower = (data && data.follower) || false
  }
}

export class QuoteExpertiseMission {
  id: string
  typeMission: string
  dateValue: string
  visit: string
  deliverType: string
  comment: string
  text: string

  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.typeMission = (data && data.typeMission) || ''
    this.dateValue = (data && data.dateValue) || ''
    this.visit = (data && data.visit) || ''
    this.deliverType = (data && data.deliverType) || ''
    this.comment = (data && data.comment) || ''
    this.text = (data && data.text) || ''
  }
}

export class JsonFee {
  title: string
  missions: boolean[]
  withoutOption: number
  withOption: number
  constructor (data?: any) {
    this.title = (data && data.title) || ''
    this.missions = (data && data.missions) || []
    this.withoutOption = (data && data.withoutOption) || 0
    this.withOption = (data && data.withOption) || 0
  }
}

export class JsonFilesRequested {
  title: string
  type: string
  demand: boolean
  received: boolean
  rows: any[]

  constructor (data?: any) {
    this.title = (data && data.title) || ''
    this.type = (data && data.type) || 'all'
    this.demand = (data && data.demand) || false
    this.received = (data && data.received) || false
    this.rows = (data && data.valid) || []
  }
}

export class Quote {
  id: string
  name: string
  status: number
  oldStatus: number | null
  delayDays: number | null
  deliverValidateDate: string | null
  billingDate: string
  deliverTime: string | null
  remisePercent: number | null
  remiseValue: number | null
  totalHT: number
  totalTTC: number
  advancedHT: number | null
  advancedTTC: number
  frais: string
  comment: string
  expertiseMission: any
  fees: any[]
  filesRequested: any
  addressComment: string
  contacts: QuoteContact[]
  addresses: QuoteAddress[]
  missions: QuoteMission[]
  files: AppFile[]
  submittedFile: AppFile
  userId: number
  billing: any
  associateFolderId: number
  notifications: any[]
  conflit: any[]
  typeDelay: string
  realisationDate: string | null
  settledFolderName: string | null
  createdAt: Date
  estimateDays: number | null
  generalComment: string | null
  conflictReason: string | null
  acceptDate: string | null
  statusHistory: any[]

  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.name = (data && data.name) || ''
    this.status = (data && data.status) || 0
    this.oldStatus = (data && data.oldStatus) || null
    this.delayDays = (data && data.delayDays) || 30
    if (data && data.deliverValidateDate) {
      this.deliverValidateDate = data.deliverValidateDate !== '-001-11-30T00:00:00+00:00' ? data.deliverValidateDate : null
    } else {
      this.deliverValidateDate = null
    }
    this.billingDate = (data && data.billingDate) || null
    this.deliverTime = (data && data.deliverTime) || ''
    this.remisePercent = (data && data.remisePercent) || 0
    this.remiseValue = (data && Math.floor(data.remiseValue || 0)) || 0
    this.totalHT = (data && data.totalHT) || 0
    this.totalTTC = (data && data.totalTTC) || 0
    this.advancedHT = (data && data.advancedHT) || 0
    this.advancedTTC = (data && data.advancedTTC) || 0
    this.frais = (data && data.frais) || ''
    this.comment = (data && data.comment) || ''
    this.expertiseMission = (data && data.expertiseMission) || {}
    this.fees = (data && data.fees) || []
    this.filesRequested = (data && data.filesRequested) || []
    this.addressComment = (data && data.addressComment) || ''
    this.contacts = (data && data.contacts) || []
    this.addresses = (data && data.addresses) || []
    this.missions = (data && data.missions) || []
    this.files = (data && data.files) || []
    this.submittedFile = (data && data.submittedFile) || null
    this.userId = (data && data.userId) || null
    this.billing = (data && data.billing) || {}
    this.associateFolderId = (data && data.associateFolder && data.associateFolder.id) || null
    this.notifications = (data && data.notifications) || []
    this.conflit = (data && data.conflit) || null
    this.typeDelay = (data && data.typeDelay) || 'Délai de réalisation en jours ouvrés'
    this.realisationDate = (data && data.realisationDate) || null
    this.settledFolderName = (data && data.settledFolderName) || null
    this.createdAt = (data && data.createdAt)
    this.estimateDays = (data && data.estimateDays) || null
    this.generalComment = (data && data.generalComment) || null
    this.conflictReason = (data && data.conflictReason) || null
    this.acceptDate = (data && data.acceptDate) || null
    this.statusHistory = (data && data.statusHistory) || null
  }
}

class QuoteService extends Service {
  private readonly _quote: BehaviorSubject<Quote | any> = new BehaviorSubject<Quote | any>({})
  private readonly _quotes: BehaviorSubject<Quote[]> = new BehaviorSubject<Quote[]>([])
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _showContactModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public onChangeQuote: Observable<Quote> = this._quote.asObservable()
  public onChangeQuotes: Observable<Quote[]> = this._quotes.asObservable()
  public onChangeShowContactModal: Observable<boolean> = this._showContactModal.asObservable()
  public onLoading: Observable<boolean> = this._loading.asObservable()

  get quote (): Quote | any {
    return this._quote.getValue()
  }

  public initQuote (data?: any): void {
    if (data) {
      if (data.missions && data.missions.length > 0) {
        data.missions.forEach((mission: any) => {
          mission.amount = (mission.amount || 0)
        })
      } else {
        data.missions = [new QuoteMission({ number: 'E1' })]
      }

      if (!data.addresses && data.addresses.length > 0) {
        data.addresses = [new QuoteAddress(), new QuoteAddress()]
      }

      if (data.contacts && data.contacts.length > 0) {
        data.contacts.forEach((contact: any) => {
          contact.contactId = contact.contact ? contact.contact.id : null
          contact.contactAddressId = contact.contactAddress ? contact.contactAddress.id : null
          contact.telephone1 = contact.contact ? contact.contact.telephone1 : null
        })
        if (!data.contacts.find((contact: QuoteContact) => contact.type === 'respondent')) {
          data.contacts.push(new QuoteContact({ type: 'respondent' }))
        }
        if (!data.contacts.find((contact: QuoteContact) => contact.type === 'applicant')) {
          data.contacts.push(new QuoteContact({ type: 'applicant' }))
        }
      } else {
        data.contacts = [
          new QuoteContact({ interlocuteur: true, mondator: true, type: 'applicant' }),
          new QuoteContact({ type: 'respondent' })
        ]
      }

      if (!data.expertiseMission) {
        data.expertiseMission = new QuoteExpertiseMission({ dateValue: MISSION_DATE_VALUES[0].value })
      }

      if (data.filesRequested.find((file: JsonFilesRequested) => file.type === 'other')) {
        data.filesRequested.splice(data.filesRequested.findIndex((file: JsonFilesRequested) => file.type === 'other'), 1)
      }

      const fetchedQuote = new Quote(data)
      this._quote.next(fetchedQuote)

      if (data.billing) {
        billingService.initBilling(data.billing)
      } else {
        billingService.initBilling()
      }

      if (data.files) {
        this.initFiles(data.files)
      }
      if (data.categories) {
        fileService.initCategoryTree(data.categories)
      }
      this.updateQuote(false, true)
    } else {
      const defaultFiles: JsonFilesRequested[] = []
      REQUESTED_FILES.forEach((file) => {
        if (file.type === 'all') {
          const rows = file.content.map((item: string) => {
            return {
              demand: false,
              received: false,
              content: item,
              observation: ''
            }
          })
          defaultFiles.push({
            title: file.title,
            type: file.type,
            demand: false,
            received: false,
            rows
          })
        }
      })
      const quote = new Quote({
        name: 'Devis',
        status: 0,
        contacts: [
          new QuoteContact({ interlocuteur: true, mondator: true, type: 'applicant' }),
          new QuoteContact({ type: 'respondent' })
        ],
        addresses: [new QuoteAddress()],
        missions: [new QuoteMission({ number: 'E1' })],
        expertiseMission: new QuoteExpertiseMission({ dateValue: MISSION_DATE_VALUES[0].value }),
        fees: [new JsonFee({ missions: [false] })],
        filesRequested: defaultFiles
      })
      this._quote.next(quote)
      billingService.initBilling()
    }
  }

  public initFiles (files: any[]): void {
    files.sort((a: any, b: any) => a.order - b.order)
    if (this.quote.submittedFile) {
      const findFile = files.find((file: any) => file.id === this.quote.submittedFile.id)
      if (findFile) {
        findFile.selected = true
      }
    }
    fileService.initFiles(files)
  }

  public showContactModal (show: boolean): void {
    this._showContactModal.next(show)
  }

  public addContact (type: string, index: number): void {
    const newContact = new QuoteContact({ type })
    this.quote.contacts.splice(index + 1, 0, newContact)
    this.updateQuote()
  }

  public deleteContact (type: string, index: number): void {
    const _sameTypes = this.quote.contacts.filter((item: QuoteContact) => item.type === type)
    if (_sameTypes.length > 1) {
      this.quote.contacts.splice(index, 1)
      this.updateQuote()
    } else if (_sameTypes.length === 1) {
      const newContact = new QuoteContact({ type })
      this.quote.contacts.splice(index, 1, newContact)
      this.updateQuote()
    }
  }

  public addAddress (index: number): void {
    const newAddress = new QuoteAddress()
    this.quote.addresses.splice(index + 1, 0, newAddress)
    const missions = this.quote.missions.map((item: QuoteMission) => false)
    const newJsonFee = new JsonFee({ missions })
    this.quote.fees.splice(index + 1, 0, newJsonFee)
    this.updateQuote()
  }

  public deleteAddress (index: number): void {
    if (this.quote.addresses.length > 1) {
      this.quote.addresses.splice(index, 1)
      this.quote.fees.splice(index, 1)
      this.updateQuote()
    } else {
      this.quote.addresses.splice(0, 1, new QuoteAddress())
    }
  }

  public addMission (index: number): void {
    const billing = billingService.billing
    let maxNumber: number = this.quote.missions.reduce((a: any, b: any): number => {
      const bId = b.number ? parseInt(b.number.replace('E', '')) : 0
      return (a > bId) ? a : bId
    }, 0)
    if (this.quote.status === 6) {
      maxNumber = billing.missions.reduce((a: any, b: any): number => {
        const bId = b.number ? parseInt(b.number.replace('E', '')) : 0
        return (a > bId) ? a : bId
      }, maxNumber)
    } else {
      billing.missions.number = 'E' + (maxNumber + 2).toString()
    }
    const newQuoteMission = new QuoteMission({ number: 'E' + (maxNumber + 1).toString() })
    this.quote.missions.splice(index + 1, 0, newQuoteMission)
    this.quote.fees.forEach((item: JsonFee) => {
      item.missions.splice(index + 1, 0, false)
    })
    this.updateMission()
  }

  public deleteMission (index: number): void {
    const billing = billingService.billing
    if (this.quote.missions.length > 1) {
      billingService.adjustBillingMissionsNumber(this.quote.missions[index].number)
      this.quote.missions.splice(index, 1)
      for (index; index < this.quote.missions.length; index++) {
        const idNumber = this.quote.missions[index].number ? parseInt(this.quote.missions[index].number.replace('E', '')) : 0
        this.quote.missions[index].number = 'E' + (idNumber - 1).toString()
      }
      if (this.quote.status !== 6) {
        const maxNumber: number = this.quote.missions.reduce((a: any, b: any): number => {
          const bId = b.number ? parseInt(b.number.replace('E', '')) : 0
          return (a > bId) ? a : bId
        }, 0)
        billing.missions.number = 'E' + (maxNumber + 1).toString()
      }
      this.quote.fees.forEach((item: JsonFee) => {
        item.missions.splice(index, 1)
      })
      this.updateMission()
    }
  }

  public updateAddress (): void {
    this.quote.addresses.forEach((item: QuoteAddress, index: number) => {
      if (this.quote.fees && this.quote.fees[index]) {
        this.quote.fees[index].title = item.buildingNumber + ' ' + item.street + ' ' + item.zip + ' ' + item.city
      }
    })
    this.updateQuote()
  }

  public updateMission (): void {
    const _available: any[] = []
    this.quote.missions.forEach((item: QuoteMission) => {
      if (item.expertType !== '') {
        if (item.expertType === "Ind. d'expropriation") {
          const findFiles = REQUESTED_FILES.filter((file: any) => {
            return file.type === 'expert' && file.valid.includes(item.expertType)
          })
          findFiles.forEach((findFile: any) => {
            const existFile = this.quote.filesRequested.find((file: any) => {
              return file.title === findFile.title
            })
            if (!existFile) {
              const rows = findFile.content.map((item: string) => {
                return {
                  demand: false,
                  received: false,
                  content: item,
                  observation: ''
                }
              })
              this.quote.filesRequested.push({
                title: findFile.title,
                type: findFile.type,
                demand: false,
                received: false,
                rows
              })
            }
            _available.push(findFile.title)
          })
        } else {
          const findFile = REQUESTED_FILES.find((file: any) => {
            return file.type === 'expert' && file.valid.includes(item.expertType)
          })
          if (findFile) {
            const existFile = this.quote.filesRequested.find((file: any) => {
              return file.title === findFile.title
            })
            if (!existFile) {
              const rows = findFile.content.map((item: string) => {
                return {
                  demand: false,
                  received: false,
                  content: item,
                  observation: ''
                }
              })
              this.quote.filesRequested.push({
                title: findFile.title,
                type: findFile.type,
                demand: false,
                received: false,
                rows
              })
            }
            _available.push(findFile.title)
          }
        }
      }
      if (item.localType !== '') {
        const findFile = REQUESTED_FILES.find((file: any) => {
          return file.type === 'local' && file.valid.includes(item.localType)
        })
        if (findFile) {
          const existFile = this.quote.filesRequested.find((file: any) => {
            return file.title === findFile.title
          })
          if (!existFile) {
            const rows = findFile.content.map((item: string) => {
              return {
                demand: false,
                received: false,
                content: item,
                observation: ''
              }
            })
            this.quote.filesRequested.push({
              title: findFile.title,
              type: findFile.type,
              demand: false,
              received: false,
              rows
            })
          }
          _available.push(findFile.title)
        }
      }
    })
    this.quote.filesRequested = this.quote.filesRequested.filter((file: JsonFilesRequested) => file.type === 'all' || _available.includes(file.title))
    const index = this.quote.filesRequested.findIndex((item: any) => item.title === 'Travaux')
    if (index != null) {
      this.quote.filesRequested.push(this.quote.filesRequested.splice(index, 1)[0])
    }
    this.updateQuote()
  }

  public updateQuote (isRemiseValue?: boolean, isUpdatedAccount?: boolean): void {
    this.calculateQuote(isRemiseValue, isUpdatedAccount)
    this._quote.next(this.quote)
    billingService.updateBilling('quote')
  }

  public calculateQuote (isRemiseValue?: boolean, isUpdatedAccount?: boolean): void {
    this.quote.fees.forEach((fee: any) => {
      let withOption = 0
      let withoutOption = 0
      fee.missions.forEach((value: boolean, index: number) => {
        if (value) {
          const amount = this.quote.missions[index].amount ? this.quote.missions[index].amount : 0
          if (this.quote.missions[index].option === true) {
            withOption += parseFloat(amount)
          } else {
            withoutOption += parseFloat(amount)
          }
        }
      })
      fee.withOption = withOption
      fee.withoutOption = withoutOption
    })

    let sumFeeWithoutOption = 0
    this.quote.fees.forEach((item: any) => {
      sumFeeWithoutOption += parseFloat(item.withoutOption)
    })
    if (isRemiseValue) {
      if (sumFeeWithoutOption !== 0) {
        this.quote.remisePercent = parseFloat(((Math.floor(this.quote.remiseValue || 0) * 100) / sumFeeWithoutOption).toFixed(3))
      } else {
        this.quote.remisePercent = 0
      }
    } // else {
    // this.quote.remiseValue = sumFeeWithoutOption * parseFloat(String((this.quote.remisePercent || 0) / 100));
    // }
    this.quote.totalHT = (sumFeeWithoutOption - Math.floor(this.quote.remiseValue || 0))
    this.quote.totalTTC = (this.quote.totalHT * 1.2)
    if (!isUpdatedAccount) {
      this.quote.advancedHT = (this.quote.totalHT / 2)
    }
    this.quote.advancedTTC = (this.quote.advancedHT * 1.2)
  }

  public async saveQuote (): Promise<any> {
    this._loading.next(true)
    if ((authService.userValue != null) && authService.userValue.id) {
      this.quote.userId = authService.userValue.id
    }
    this.quote.billing = billingService.billing
    if (this.quote.typeDelay !== 'Délai de réalisation en jours ouvrés') {
      this.quote.delayDays = null
      this.quote.deliverTime = null
    } else {
      this.quote.realisationDate = null
    }
    return await apiClient.post('quote', this.quote).then((res: any) => {
      res.data.quote.conflit = res.data.conflit
      this.initQuote(res.data.quote)
      this._loading.next(false)
      return res.data
    })
  }

  public async deleteQuote (): Promise<any> {
    this._loading.next(true)
    return await apiClient.delete('quote/' + String(this.quote.id)).then(() => {
      this.initQuote()
      this._loading.next(false)
      return 'success'
    })
  }

  public async duplicateQuote (): Promise<any> {
    return await new Promise((resolve: any, reject: any) => {
      if ((authService.userValue != null) && authService.userValue.id) {
        this.quote.userId = authService.userValue.id
      }
      localStorage.setItem('duplicatedQuote', JSON.stringify(this.quote))
      resolve('success')
    })
  }

  public initDuplicateQuote (data: any): void {
    const quote = data
    quote.id = null
    quote.status = 0
    quote.name = 'Devis'
    quote.expertiseMission.id = null
    quote.missions.forEach((item: any) => {
      item.id = null
    })
    quote.contacts.forEach((item: any) => {
      item.id = null
    })
    quote.addresses.forEach((item: any) => {
      item.id = null
    })
    quote.billing = null
    quote.files = []
    this.initQuote(quote)
  }

  public async getStatusHistory (): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('quote/' + String(this.quote.id) + '/statusHistory').then((res: any) => {
      this.quote.statusHistory = res.data.statusHistory
      this.updateQuote()
      this._loading.next(false)
      return res.data
    })
  }

  public async submitQuote (fileId: string, comment: string): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('quote/submit', {
      quote: this.quote,
      fileId,
      userId,
      comment,
      sendToAdobeSign: true,
      oldStatus: this.quote.status
    }).then((res: any) => {
      this.quote.status = res.data.status
      if (res.data.files) {
        this.quote.submittedFile = res.data.files.find((file: any) => file.id === fileId)
        this.initFiles(res.data.files)
      }
      this.updateQuote()
      this._loading.next(false)
      return res.data
    })
  }

  public async cancelSubmit (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('quote/submit/cancel', {
      quoteId: this.quote.id,
      userId,
      oldStatus: this.quote.status
    }).then((res: any) => {
      this.quote.status = res.data.status
      if (res.data.files) {
        this.initFiles(res.data.files)
      }
      this.updateQuote()
      this._loading.next(false)
      return res.data
    })
  }

  public async acceptValidation (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('quote/validation/accept', {
      quoteId: this.quote.id,
      userId,
      oldStatus: this.quote.status
    }).then((res: any) => {
      this.quote.status = res.data.status
      if (res.data.files) {
        this.initFiles(res.data.files)
      }
      this.updateQuote()
      this._loading.next(false)
      return res.data
    })
  }

  public async validateQuote (comment: string): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('quote/validate', {
      quote: this.quote,
      comment,
      sendToAdobeSign: false,
      userId,
      oldStatus: this.quote.status
    }).then((res: any) => {
      this.quote.status = res.data.status
      if (res.data.files) {
        this.initFiles(res.data.files)
      }
      this.updateQuote()
      this._loading.next(false)
      return res.data
    })
  }

  public async refuseValidation (comment: string): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('quote/validation/refuse', {
      quoteId: this.quote.id,
      comment,
      userId,
      oldStatus: this.quote.status
    }).then((res: any) => {
      this.quote.status = res.data.status
      if (res.data.files) {
        this.initFiles(res.data.files)
      }
      this.updateQuote()
      this._loading.next(false)
      return res.data
    })
  }

  public async emailSent (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('quote/email', {
      quoteId: this.quote.id,
      userId,
      oldStatus: this.quote.status
    }).then((res: any) => {
      this.quote.status = res.data.status
      this.updateQuote()
      this._loading.next(false)
      return res.data
    })
  }

  public async sendToFolder (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('quote/folder', {
      quoteId: this.quote.id,
      userId,
      oldStatus: this.quote.status
    }).then((res: any) => {
      this.quote.status = res.data.status
      this.updateQuote()
      this._loading.next(false)
      return res.data
    }).catch((err) => {
      console.error(err)
      this._loading.next(false)
      throw err
    })
  }

  public async associateToFolder (folderId: string): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('quote/associate', {
      quoteId: this.quote.id,
      folderId
    }).then((res: any) => {
      this.quote.associateFolderId = folderId
      this.updateQuote()
      this._loading.next(false)
      return res.data
    })
  }

  public async unassociateToFolder (): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('quote/unassociate', {
      quoteId: this.quote.id
    }).then((res: any) => {
      this.quote.associateFolderId = null
      this.updateQuote()
      this._loading.next(false)
      return res.data
    })
  }

  public async getQuoteById (id: string): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('quote/' + Number(id).toString()).then((res: any) => {
      res.data.quote.conflit = res.data.conflit
      this.initQuote(res.data.quote)
      this._loading.next(false)
      return res.data
    })
  }

  public async generateDocument (): Promise<any> {
    const client = this.quote.contacts.find((contact: any) => contact.mandator === true && contact.type === 'applicant')
    let clientName = ''
    if (client && client.contact) {
      clientName = client && client.contact.prenom != null ? removeSpecialCharacters(client.contact.nom) + '_' + removeSpecialCharacters(client.contact.prenom) : removeSpecialCharacters(client.contact.nom)
    }
    const address = this.quote.addresses ? String(this.quote.addresses[0].buildingNumber) + '_' + removeSpecialCharacters(this.quote.addresses[0].street) + '_' + String(this.quote.addresses[0].zip) + '_' + removeSpecialCharacters(this.quote.addresses[0].city) : null
    this._loading.next(true)
    return await axios.get(VUE_APP_RAPPORT_API_URL + 'quote/' + String(this.quote.id) + '/download', {
      responseType: 'blob'
    }).then((res: any) => {
      this._loading.next(false)
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', String(this.quote.name) + '_' + String(address) + '_' + clientName + '.docx') // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
      return 'success'
    })
  }

  public async checkNotification (notificationId: string): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('quote/notification/check', {
      notificationId
    }).then((res: any) => {
      this._loading.next(false)
      return res.data
    })
  }

  public async saveCategoryTree (folderId: number, newTree: CategoryTree[]): Promise<void> {
    await apiClient.post('quote/category-tree', { folderId, newTree }).then(({ data }) => {
      if (data.categories) {
        fileService.initCategoryTree(data.categories)
        this.initFiles(data.files)
      }
    })
  }

  public async generatePdf (fileId: string): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('quote/generate-pdf', {
      quoteId: this.quote.id,
      fileId
    }).then((res: any) => {
      if (res.data.files) {
        this.initFiles(res.data.files)
      }
      this.updateQuote()
      this._loading.next(false)
      return res.data
    })
  }

  public async sendToAdobeSign (): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('quote/send/adobesign', {
      quoteId: this.quote.id
    }).then((res: any) => {
      this._loading.next(false)
      return res.data
    })
  }

  public async validate (): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('quote/validate/' + String(this.quote.id)).then((res: any) => {
      this.initQuote()
      this._loading.next(false)
      return 'success'
    })
  }

  public async getQuoteConflicts (quoteId: number): Promise<void> {
    this._loading.next(true)
    return await apiClient.get('quote/conflit/' + quoteId.toString()).then((res: any) => {
      this._loading.next(false)
      return res.data
    })
  }

  public async deleteQuoteById (folderId: number): Promise<any> {
    this._loading.next(true)
    await apiClient.delete('quote/' + folderId.toString()).then((res: any) => {
      this._loading.next(false)
    }).catch((err) => {
      console.error(err)
      this._loading.next(false)
      throw err
    })
  }
}

export const quoteService = new QuoteService()
