
import { Component, Vue, Watch } from 'vue-property-decorator'
import { tap } from 'rxjs/operators'
import CustomModal from '@/components/utils/Modal.vue'
import CustomToggleDouble from '@/components/form/ToggleDouble.vue'
import CustomToggle from '@/components/form/Toggle.vue'
import CustomFileUpload from '@/components/form/FileUpload.vue'
import RichEditor from '@/components/element/RichEditor.vue'
import MapPreview from '@/components/element/MapPreview.vue'
import { apiClient, baseUrl } from '@/services/api.client'
import { Ged, ListTheme, ListContent } from '@/models/ged.model'
import { LegalFolder } from '@/models/legalFolder.model'
import { gedService } from '@/services/ged.service'
import { listReferenceService, ReferenceAddress } from '@/services/listReference.service'
import { legalFolderService } from '@/services/legalFolder.service'
import { settledFolderService, SettledFolder } from '@/services/settledFolder.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { authService } from '@/services/auth.service'
import { validPeriodString } from '@/utils/functions'
import { shortcutsDatePicker } from '@/utils/constant'

@Component({
  subscriptions (this: any) {
    const self = this
    return {
      showFormModal$: gedService.onChangeShowFormModal.pipe(
        tap((res: boolean) => {
          if (res && self.$refs.gedForm) {
            (self.$refs.gedForm as any).clearValidate()
          }
        })
      ),
      legalFolders$: legalFolderService.onChange,
      settledFolders$: settledFolderService.onChangeFolders,
      loadingSettledFolder$: settledFolderService.onLoading,
      loadingLegalFolder$: legalFolderService.onLoading,
      me$: authService.onChange
    }
  },
  components: {
    CustomModal,
    CustomToggleDouble,
    CustomToggle,
    CustomFileUpload,
    RichEditor,
    MapPreview
  }
})

export default class FormGED extends Vue {
  me$: User
  baseUrl: string | undefined = baseUrl
  inputValue: string = ''
  ged$: Ged = new Ged()
  legalFolders$: LegalFolder[]
  settledFolders$: SettledFolder[]
  loadingSettledFolder$: boolean
  loadingLegalFolder$: boolean
  listThemes: ListTheme[] = []
  listContents: ListContent[] = []
  referenceAddress$: ReferenceAddress = new ReferenceAddress()

  CTAToggleMode = 'document'
  rules: any = {
    nom: [
      { required: true, message: 'Le champ nom est obligatoire', trigger: 'change', suppressWarning: false }
    ],
    source: [
      { required: true, message: 'Le champ source est obligatoire', trigger: 'change' }
    ],
    listTheme: [
      { required: true, message: 'Le champ thème est obligatoire', trigger: 'change' }
    ],
    listContent: [
      { required: true, message: 'Le champ contenu est obligatoire', trigger: 'change' }
    ],
    /* folder: [
                { required: true, message: 'Le champ dossier est obligatoire', trigger: 'change' }
            ], */
    date: [
      { required: true, validator: this.dateValidator, trigger: 'change' }
    ],
    champsUpload: [
      { required: true, message: 'Un fichier est obligatoire', trigger: 'blur' }
    ],
    note: [
      { required: true, message: 'Le champ note est obligatoire', trigger: 'change' }
    ],
    tags: [
      { required: true, type: 'array', message: 'Un tag est obligatoire', trigger: 'blur' }
    ]
  }

  newCTA = {
    confidentialite: false
  }

  rangePickerOptions: any = {
    shortcuts: shortcutsDatePicker
  }

  loadingUpload: boolean = false

  @Watch('ged$.type', { deep: true })
  onChnageType () {
    this.initContent()
    const form: any = this.$refs.gedForm
    if (form) {
      form.clearValidate()
    }
  }

  @Watch('ged$.listContent', { deep: true })
  onChnageListContent (val: ListContent) {
    if (val && val.name === 'Note') {
      this.ged$.type = 'note'
    }
  }

  initContent () {
    if (this.ged$.type === 'note') {
      const listContent = this.listContents.find((item: ListContent) => item.name === 'Note')
      if (listContent) {
        this.ged$.listContent = listContent
      }
    }
  }

  get folderList (): any[] {
    const folderList: any[] = []
    folderList.push({
      label: 'Dossiers sélectionnées',
      options: this.ged$.folders
    })
    folderList.push({
      label: 'Dossier judiciaire',
      options: this.legalFolders$
        .filter((item: LegalFolder) => this.ged$.folders.findIndex((gedSearch: any) => item.id === gedSearch.id) === -1)
    })
    folderList.push({
      label: 'Dossier amiable',
      options: this.settledFolders$
        .filter((item: SettledFolder) => this.ged$.folders.findIndex((gedSearch: any) => item.id === gedSearch.id) === -1)
    })
    return folderList
  }

  created () {
    gedService.onChangeGed.subscribe((data) => {
      this.ged$ = data
    })
    gedService.getListThemes().then((res: ListTheme[]) => {
      this.listThemes = res
      const temp = this.listThemes[20]
      this.listThemes[20] = this.listThemes[21]
      this.listThemes[21] = temp
    })
    gedService.getListContents().then((res: ListContent[]) => {
      this.listContents = res
    })
    legalFolderService.getFolders(0, 50)
    settledFolderService.getFolders(0, 50)
  }

  dateValidator (rule: any, value: any, callback: any) {
    if (!this.ged$.dateStart || !this.ged$.dateEnd) {
      return callback(new Error('Le champ date est obligatoire'))
    }
    if (!validPeriodString(this.ged$.dateStart, this.ged$.dateEnd)) {
      return callback(new Error('Vous ne pouvez pas choisir une plage de date !'))
    }
    callback()
  }

  handleTagClose (tag: string) {
    this.ged$.tags.splice(this.ged$.tags.indexOf(tag), 1)
  }

  handleTagConfirm () {
    const inputValue = this.inputValue
    if (inputValue && this.ged$.tags.indexOf(inputValue) < 0) {
      this.ged$.tags.push(inputValue)
    }
    this.inputValue = ''
  }

  querySearch (queryString: string, cb: Function) {
    if (!queryString) return
    listReferenceService.getReferenceAddresse(queryString).then(res => {
      cb(res)
    })
  }

  handleSelectAddress (peliasAddress: ReferenceAddress) {
    this.referenceAddress$ = peliasAddress
    this.ged$.layer = peliasAddress.layer
    this.ged$.housenumber = peliasAddress.housenumber
    this.ged$.street = peliasAddress.street
    this.ged$.postalcode = peliasAddress.postalcode
    this.ged$.country = peliasAddress.country
    this.ged$.macroregion = peliasAddress.macroregion
    this.ged$.locality = peliasAddress.locality
    this.ged$.borough = peliasAddress.borough
    this.ged$.label = peliasAddress.label
    this.ged$.lat = peliasAddress.lat
    this.ged$.lng = peliasAddress.lon
  }

  handleSelectLocation ({ lat, lon }: any): void {
    this.ged$.lat = lat
    this.ged$.lng = lon
  }

  showFormModal (show: boolean): void {
    gedService.showFormModal(show)
  }

  handleUpdateDate (val: any) {
    if (val && val.length > 1) {
      this.ged$.dateStart = val[0]
      this.ged$.dateEnd = val[1]
    } else {
      this.ged$.dateStart = ''
      this.ged$.dateEnd = ''
    }
  }

  handleSuccess (response: any) {
    this.loadingUpload = false
    if (response) {
      this.ged$.champsUpload = response
    }
  }

  handleError (response: any) {
    this.loadingUpload = false
    this.$message({
      message: JSON.parse(response).message,
      type: 'error',
      offset: 65
    })
  }

  handleRemove (file: any) {
    if (file.response && file.response.fileName) {
      this.ged$.champsUpload = null
      apiClient.delete('ged/file/' + file.response.fileName)
    }
  }

  handleBeforeUpload (file: any) {
    this.loadingUpload = true
    if (!this.ged$.nom) {
      this.ged$.nom = file.name
    }
  };

  validate (): Promise<boolean> {
    return new Promise((resolve: any) => {
      (this.$refs.gedForm as any).validate((valid: boolean) => {
        resolve(valid)
      })
    })
  }

  async handleSave () {
    const isValid: Boolean = await this.validate()
    if (isValid) {
      await gedService.saveGed()
      gedService.showFormModal(false)
      gedService.searchGed()
    }
  }

  handleDelete () {
    this.$confirm('Voulez-vous supprimer définitivement ce document ?', '', {
      confirmButtonText: 'Confirmation',
      cancelButtonText: 'Annuler',
      type: 'warning'
    }).then(() => {
      gedService.deleteGed().then(() => {
        // TODO Fix it
        setTimeout(() => {
          gedService.searchGed()
        }, 500)
        gedService.showFormModal(false)
      })
    }).catch(() => {
    })
  }

  hasRightModification () {
    if (!userService.hasRight(this.me$, USER_RIGHTS.DOCUMENT_MODIFICATION)) {
      return false
    } else {
      return true
    }
  }

  searchFolder (query: string) {
    settledFolderService.searchFolders([{ name: query }], 0, 10)
    legalFolderService.searchFolders([{ name: query }], 0, 10)
  }

  handleClose (done: any) {
    this.$confirm('Êtes vous sur de vouloir quitter sans sauvegarder ?').then(() => {
      done()
    }).catch(() => {
    })
  }

  isUserAllowedToDeleteGed () {
    const user = JSON.parse(JSON.stringify(authService.userValue))
    return userService.hasRight(user, USER_RIGHTS.DELETE_DOCUMENT)
  }
}
