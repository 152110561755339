const shortcutsDatePicker = [
  {
    text: 'Année',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-01-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-12-31T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  },
  {
    text: 'S1',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-01-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-06-30T23:59:59`)
      picker.$emit('pick', [start, end])
    }

  }, {
    text: 'S2',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-07-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-12-31T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'T1',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-01-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-03-31T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'T2',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-04-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-06-30T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'T3',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-07-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-09-30T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'T4',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-10-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-12-31T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'Janvier',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-01-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-01-31T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'Février',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-02-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-02-28T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'Mars',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-03-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-03-31T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'Avril',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-04-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-04-30T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'Mai',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-05-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-05-31T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'Juin',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-06-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-06-30T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'Juillet',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-07-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-07-31T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'Août',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-08-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-08-31T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'Septembre',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-09-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-09-30T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'Octobre',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-10-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-10-31T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'Novembre',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-11-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-11-30T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: 'Décembre',
    onClick (picker: any) {
      const start = new Date(`${String(picker.leftYear)}-12-01T00:00:00`)
      const end = new Date(`${String(picker.leftYear)}-12-31T23:59:59`)
      picker.$emit('pick', [start, end])
    }
  }
]

const shortcutsMonthDatePicker = [
  {
    value: 1,
    label: 'Janvier'
  },
  {
    value: 2,
    label: 'Février'
  },
  {
    value: 3,
    label: 'Mars'
  },
  {
    value: 4,
    label: 'Avril'
  },
  {
    value: 5,
    label: 'Mai'
  },
  {
    value: 6,
    label: 'Juin'
  },
  {
    value: 7,
    label: 'Juillet'
  },
  {
    value: 8,
    label: 'Août'
  },
  {
    value: 9,
    label: 'Septembre'
  },
  {
    value: 10,
    label: 'Octobre'
  },
  {
    value: 11,
    label: 'Novembre'
  },
  {
    value: 12,
    label: 'Décembre'
  }
]

const semesterList = [
  {
    label: 'S1',
    value: '{"start":"01-01T00:00:00", "end":"06-30T23:59:59"}'
  },
  {
    label: 'S2',
    value: '{"start":"07-01T00:00:00", "end":"12-31T23:59:59"}'
  }
]

const trimesterList = [
  {
    label: 'T1',
    value: '{"start":"01-01T00:00:00", "end":"03-31T23:59:59"}'
  },
  {
    label: 'T2',
    value: '{"start":"04-01T00:00:00", "end":"06-30T23:59:59"}'
  },
  {
    label: 'T3',
    value: '{"start":"07-01T00:00:00", "end":"09-30T23:59:59"}'
  },
  {
    label: 'T4',
    value: '{"start":"10-01T00:00:00", "end":"12-31T23:59:59"}'
  }
]

const monthList = [
  {
    label: 'Janvier',
    value: '{"start":"01-01T00:00:00", "end":"01-31T23:59:59"}'
  },
  {
    label: 'Février',
    value: '{"start":"02-01T00:00:00", "end":"02-29T23:59:59"}'
  },
  {
    label: 'Mars',
    value: '{"start":"03-01T00:00:00", "end":"03-31T23:59:59"}'
  },
  {
    label: 'Avril',
    value: '{"start":"04-01T00:00:00", "end":"04-30T23:59:59"}'
  },
  {
    label: 'Mai',
    value: '{"start":"05-01T00:00:00", "end":"05-31T23:59:59"}'
  },
  {
    label: 'Juin',
    value: '{"start":"06-01T00:00:00", "end":"06-30T23:59:59"}'
  },
  {
    label: 'Juillet',
    value: '{"start":"07-01T00:00:00", "end":"07-31T23:59:59"}'
  },
  {
    label: 'Août',
    value: '{"start":"08-01T00:00:00", "end":"08-31T23:59:59"}'
  },
  {
    label: 'Septembre',
    value: '{"start":"09-01T00:00:00", "end":"09-30T23:59:59"}'
  },
  {
    label: 'Octobre',
    value: '{"start":"10-01T00:00:00", "end":"10-31T23:59:59"}'
  },
  {
    label: 'Novembre',
    value: '{"start":"11-01T00:00:00", "end":"11-30T23:59:59"}'
  },
  {
    label: 'Décembre',
    value: '{"start":"12-01T00:00:00", "end":"12-31T23:59:59"}'
  }
]

const quoteRouteName = 'Quote'
const settledFolderRouteName = 'settled-folder'

export {
  shortcutsDatePicker,
  shortcutsMonthDatePicker,
  semesterList,
  trimesterList,
  monthList,
  quoteRouteName,
  settledFolderRouteName
}
